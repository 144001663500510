import { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { Column, Pie } from "@ant-design/charts";
import LoadingPage from "../../../../components/Loading";
import { getNationStatistics } from "../../../../service/Account";

const NationStatisticsPage = () => {
  const [nationStatisticsData, setNationStatisticsData] = useState<
    NationStatisticsRes[]
  >([]);
  const [loading, setLoading] = useState(false);

  const columnConfig: any = {
    data: nationStatisticsData,
    xField: "type",
    yField: "value",
    verticalLimitLength: 100,
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: { alias: "국가" },
      value: { alias: "가입자 수" },
    },
  };

  const pieConfig = {
    appendPadding: 10,
    data: nationStatisticsData,
    angleField: "value",
    colorField: "type",
    legend: {
      pageNavigator: {
        marker: {
          style: {
            inactiveFill: "#000",
            fill: "#000",
            opacity: 0.8,
            size: 12,
          },
        },
        text: {
          style: {
            fill: "#ccc",
            fontSize: 8,
          },
        },
      },
    },
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: function content(_ref) {
        var percent = _ref.percent;
        return "".concat((percent * 100).toFixed(0), "%");
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getNationStatistics().then(setNationStatisticsData);
      setLoading(false);
    })();
  }, []);

  if (loading) return <LoadingPage loading={loading} />;
  // @ts-ignore
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card title="국가별 분포(원형그래프)" style={{ width: "100%" }}>
            <Pie {...pieConfig} />
          </Card>
        </Col>

        <Col span={24}>
          <Card title="국가별 분포(막대그래프)" style={{ width: "100%" }}>
            <Column {...columnConfig} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NationStatisticsPage;
