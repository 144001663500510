import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Badge, Button, Image, Space } from "antd";
import moment from "moment";


import LoadingPage from "../../../components/Loading";

import BaseTable from "../../../components/Table/BaseTable";
import AddTopBannerModal from "../../../components/Modal/banner/addBanner";
import DeleteTopBannerModal from "../../../components/Modal/banner/deleteBanner";
import ModifyTopBannerModal from "../../../components/Modal/banner/modifyBanner";
import useModalHandler from "../../../hooks/useModalHandler";
import {getAllTopBanners} from "../../../service/Banner";
import {HeaderContainer} from "../../../Accounts";

const TopBannerPage = () => {
    const [topBanners, setTopBanners] = useState<TopBannerResBody[]>([]);
    const [loading, setLoading] = useState(false);
    const [bannerId, setBannerId] = useState<number | null>(null);
    const [
        isVisibleAddBannerModal,
        onChangeAddBannerModal,
        setIsVisibleAddBannerModal,
    ] = useModalHandler(false);

    const [
        isVisibleDeleteBannerModal,
        onChangeDeleteBannerModal,
        setIsVisibleDeleteBannerModal,
    ] = useModalHandler(false);

    const [
        isVisibleUpdateBannerModal,
        onChangeUpdateBannerModal,
        setIsVisibleUpdateBannerModal,
    ] = useModalHandler(false);

    const onDeleteBtnClick = (id: number) => () => {
        setBannerId(id);
        onChangeDeleteBannerModal();
    };

    const onModifyBtnClick = (id: number) => () => {
        setBannerId(id);
        onChangeUpdateBannerModal();
    };

    useEffect(() => {
        bootstrap();
    }, []);

    const topBannerColumn: ColumnsType<TopBannerResBody> = [
        { key: "idx", dataIndex: "idx", title: "id", align: "center" },
        {
            key: "url",
            dataIndex: "url",
            title: "url",
            align: "center",
            render: (v) =>
                v ? (
                    <a href={v} target="_blank">
                        링크
                    </a>
                ) : (
                    "-"
                ),
        },

        {
            key: "background",
            dataIndex: "background",
            title: "이미지",
            align: "center",
            width: 500,
            render: (v) => <Image src={v} />,
        },
        {
            key: "popup",
            dataIndex: "popup",
            title: "팝업여부",
            align: "center",
            render: (v) =>
                v ? (
                    <Badge color="green" text="활성화" />
                ) : (
                    <Badge color="red" text="비활성화" />
                ),
        },
        {
            key: "enabled",
            dataIndex: "enabled",
            title: "활성화 여부",
            align: "center",
            render: (val) =>
                val ? (
                    <Badge color="green" text="활성화" />
                ) : (
                    <Badge color="red" text="비활성화" />
                ),
        },
        {
            key: "timestamp",
            dataIndex: "timestamp",
            title: "생성일",
            align: "center",
            sorter: (prev, next) =>
                moment(prev.timestamp).unix() - moment(next.timestamp).unix(),
            render: (_, v) => <>{moment(v.timestamp).format("YYYY-MM-DD")}</>,
        },
        {
            key: "modify",
            title: "수정 / 삭제",
            align: "center",
            render: (_, b) => (
                <Space size="middle">
                    <a onClick={onModifyBtnClick(b.idx)}>수정</a>
                    <a onClick={onDeleteBtnClick(b.idx)}>삭제</a>
                </Space>
            ),
        },
    ];

    const bootstrap = async () => {
        setLoading(true);
        await getAllTopBanners().then(setTopBanners);
        setLoading(false);
    };

    if (loading) return <LoadingPage loading={loading} />;

    return (
        <>
            <HeaderContainer>
                <h2>상단배너({topBanners.length})</h2>
                <Button onClick={onChangeAddBannerModal}>상단배너 추가</Button>
            </HeaderContainer>

            <div>
                <BaseTable
                    datasource={topBanners.map((b, i) => ({ key: String(i), ...b }))}
                    columnType={topBannerColumn}
                />
            </div>
            <AddTopBannerModal
                isVisible={isVisibleAddBannerModal}
                modalHandler={onChangeAddBannerModal}
            />

            <DeleteTopBannerModal
                isVisible={isVisibleDeleteBannerModal}
                modalHandler={onChangeDeleteBannerModal}
                id={bannerId}
            />

            <ModifyTopBannerModal
                isVisible={isVisibleUpdateBannerModal}
                modalHandler={onChangeUpdateBannerModal}
                id={bannerId}
            />
        </>
    );
};

export default TopBannerPage;
