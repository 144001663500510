import axios from 'axios'
import {ADMIN_API_URL} from "../constants";

export const AdminApi = axios.create({
    baseURL: ADMIN_API_URL
})

const token = localStorage.getItem("access_token");

AdminApi.defaults.headers = {
    authorization: `Bearer ${token}`
} as any;


