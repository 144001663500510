import { Button, Form, Input, Modal, InputNumber } from "antd";
import { VFC } from "react";
import { coinWithdraw } from "../../../service/CoinWithdraw";

const CoinWithdrawModal: VFC<ModalProps> = ({ isVisible, modalHandler, id, coinIndex }) => {

  const onFinish = async (value: CoinWithdrawReqBody) => {
    const uid = id;
    const coinidx = coinIndex;
    const address = value.address;
    const amount = value.amount;

    try {
      await coinWithdraw({ uid, coinidx, address, amount });
      alert("정상적으로 출금되었습니다.");
      // window.location.reload();
    } catch (err) {
      if (err.response.data.message.hasOwnProperty('message')){ //throw err에 'message' 속성 포함시(자체 throw)
        alert(`Error : ${err.response.data.message.message}`);
      }else {
        alert(`Error : ${err.response.data.message.code}`);
      }
    }
  };

  const onFinishFailed = () => {};

  return (
    <Modal
      title={"코인 출금"}
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="uid"
          name="uid"
        >
          <span></span>
          <Input value={id}/>
        </Form.Item>
        
        <Form.Item
          label="coinidx"
          name="coinidx"
        >
          <span></span>
          <Input value={coinIndex}/>
        </Form.Item>

        <Form.Item
          label="받는사람 주소"
          name="address"
          rules={[{ required: true, message: "받는사람의 코인주소를 입력해주세요!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="수량"
          name="amount"
          rules={[{ required: true, message: "보낼 코인 수량을 입력해주세요!" }]}
        >
          {coinIndex != 1 ?
              <InputNumber
                style={{width:200}}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
          :
              <InputNumber
                style={{width:200}}
              />
          }
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" htmlType="submit">
            전송
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CoinWithdrawModal;
