import moment from "moment";

export const parseDateTime = (timestamp: Date | null | string) => {
  if (!timestamp) {
    return "-";
  }
  return moment(timestamp).format("YYYY.MM.DD HH:mm");
};

export const parsePhoneNumber = (phoneNumber: string | null) => {
  if (!phoneNumber) {
    return "-";
  }
  if (phoneNumber.slice(0, 2) === "01") {
    return `010-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
  }
  return `010-${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const convertBalance = (balance: string) => {
  return Number.parseFloat(balance).toFixed(0);
};
