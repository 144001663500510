import { Button, Card, Col, DatePicker, Row, Statistic } from "antd";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/charts";
import moment from "moment";
import { useEffect, useState } from "react";
import LoadingPage from "../../../components/Loading";
import { getUsersDailyRegistration } from "../../../service/Account";
import {
  getDailyCumulativeUsersCount,
  getUsersCount,
} from "../../../service/User";

const UserDashboardPage = () => {
  const dateFormat = "YYYY-MM-DD";
  const [loading, setLoading] = useState(false);
  const [rangeDefaultDate, setRangeDefaultDate] = useState([
    moment(new Date(), dateFormat),
    moment(new Date(), dateFormat).add(-180, "d"),
  ]);
  const [rangeDate, setRangeDate] = useState<string[]>([]);
  const [lineGraphData, setLineGraphData] = useState([]);
  const [usersCount, setUsersCount] = useState<{ count: string }[] | null>(
    null
  );
  const [dailyCumulativeUsers, setDailyCumulativeUsers] = useState<
    { date: string; sum: number }[]
  >([]);

  const lineGraphConfig = {
    height: 300,
    data: lineGraphData.slice(0, lineGraphData.length - 1),
    lineStyle: {
      color: "#4759AAFF",
      stroke: "#4759AAFF",
      lineWidth: 5,
      cursor: "pointer",
    },
    point: {
      size: 4,
      shape: "round",
      style: {
        fill: "#e8ba24",
        lineWidth: 1,
      },
    },
    xField: "date",
    yField: "users",
    interactions: [{ type: "marker-active" }],
  };

  const dailyCumulativeGraphConfig = {
    height: 300,
    data: dailyCumulativeUsers.slice(0, lineGraphData.length - 1),
    lineStyle: {
      color: "#4759AAFF",
      stroke: "#4759AAFF",
      lineWidth: 5,
      cursor: "pointer",
    },
    point: {
      size: 4,
      shape: "round",
      style: {
        fill: "#e8ba24",
        lineWidth: 1,
      },
    },
    xField: "date",
    yField: "sum",
    interactions: [{ type: "marker-active" }],
  };

  const { RangePicker } = DatePicker;

  const onLookUpBtnClickHandler = async () => {
    const endDate =
      rangeDate.length > 0
        ? moment(rangeDate[1]).format("YYYY-MM-DD")
        : moment(rangeDefaultDate[1]).format("YYYY-MM-DD");

    const startDate =
      rangeDate.length > 0
        ? moment(rangeDate[0]).format("YYYY-MM-DD")
        : moment(rangeDefaultDate[0]).format("YYYY-MM-DD");

    await getUsersDailyRegistration({
      startDate,
      endDate,
    }).then(setLineGraphData);
  };

  console.log(dailyCumulativeUsers, "here");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getUsersDailyRegistration({
        startDate: moment(rangeDefaultDate[1]).format("YYYY-MM-DD"),
        endDate: moment(rangeDefaultDate[0]).format("YYYY-MM-DD"),
      });
      setLineGraphData(result);
      await getUsersCount().then(setUsersCount);
      await getDailyCumulativeUsersCount().then(setDailyCumulativeUsers);
    })();
    setLoading(false);
  }, []);

  if (loading) return <LoadingPage loading={loading} />;
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card title="가입자 수" style={{ width: "100%" }}>
            <Row>
              <Col span={12} style={{ display: "flex" }}>
                <Statistic
                  style={{ marginRight: "30px" }}
                  prefix={<TeamOutlined />}
                  suffix={usersCount && usersCount[0].count}
                  title="전체 가입자 수"
                  valueStyle={{
                    color: "#3f8600",
                    fontSize: 36,
                  }}
                  value={" "}
                />
                <Statistic
                  prefix={<UserOutlined />}
                  suffix={
                    lineGraphData.length &&
                    lineGraphData[lineGraphData.length - 1]["date"] ===
                      moment(new Date()).format("YYYY-MM-DD")
                      ? lineGraphData[lineGraphData.length - 1]["users"]
                      : 0
                  }
                  title="오늘 가입자수"
                  valueStyle={{
                    color: "#3f8600",
                    fontSize: 36,
                  }}
                  value={" "}
                />
              </Col>
              <Col span={12}>
                <div
                  style={{
                    lineHeight: "1.5715",
                    color: "rgba(0, 0, 0, 0.45)",
                    marginBottom: "20px",
                  }}
                >
                  기간 선택
                </div>
                <RangePicker
                  defaultValue={[
                    moment(new Date(), dateFormat),
                    moment(new Date(), dateFormat).add(-180, "d"),
                  ]}
                  format={dateFormat}
                  onChange={async (e) => {
                    setRangeDate([
                      moment(e[0]).format("YYYY-MM-DD"),
                      moment(e[1]).format("YYYY-MM-DD"),
                    ]);
                  }}
                />
                <Button
                  onClick={onLookUpBtnClickHandler}
                  style={{ marginLeft: "20px" }}
                >
                  조회하기
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title="일별 가입자 추이"
            // extra={<a href="#">CSV 다운받기</a>}
            style={{ width: "100%" }}
          >
            {/*  @ts-ignore */}
            <Line {...lineGraphConfig} />
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title="누적 가입자 추이"
            // extra={<a href="#">CSV 다운받기</a>}
            style={{ width: "100%" }}
          >
            <Line {...dailyCumulativeGraphConfig} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserDashboardPage;
