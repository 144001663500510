import { atom } from "recoil";

export const AccountInfoAtom = atom<AccountInfo>({
  key: "accountInfo",
  default: {
    email: "",
    name: "",
    adminId: 0,
    level: 0,
  },
});
