// 로그인 요청
import { AdminApi } from "../index";
import moment from "moment";

// 로그인
export async function login(body: { email: string; password: string }) {
  return AdminApi.post<Token>("/login", body).then((resp) => resp.data);
}

// 내 정보 가져오기
export async function getMyInfo() {
  return AdminApi.get<AccountInfo>("/accounts/me").then((resp) => resp.data);
}

// 어드민 전체계정 가져오기
export async function getAdminAccounts() {
  return AdminApi.get<AdminAccountReqBody[]>("accounts").then(
    (resp) => resp.data
  );
}

// 어드민 계정 생성
export async function createAccount(body: CreateAdminAccountReqBody) {
  return AdminApi.post("/accounts", body).then((resp) => resp.data);
}

export async function deleteAccount(id: number) {
  return AdminApi.delete(`/accounts/${id}`);
}

export async function getUsersDailyRegistration(date: {
  startDate: string;
  endDate: string;
}) {
  const { startDate, endDate } = date;
  return AdminApi.get<DailyUserRegistrationRes[]>(
    "/dashboard/user_registration",
    {
      params: {
        startDate,
        endDate,
      },
    }
  ).then((resp) => {
    return resp.data.map((d) => {
      if (typeof d.users === "string") {
        return {
          users: parseInt(d.users),
          date: moment(d.date).format("YYYY-MM-DD"),
        };
      }
    });
  });
}

export async function getNationStatistics() {
  return AdminApi.get<NationStatisticsRes[]>(
    "/dashboard/country/statistics"
  ).then((resp) => {
    return resp.data.map((d) => {
      if (typeof d.value === "string") {
        return {
          value: parseInt(d.value),
          percentage: d.percentage,
          type: d.type,
        };
      }
    });
  });
}

export async function getUserInfo(uid: string) {
  return AdminApi.get<AccountResBody>(`/users/${uid}`).then(
    (resp) => resp.data
  );
}

export async function updateUserInfo(uid: string, body: UpdateUserInfo) {
  return AdminApi.put(`/users/${uid}`, body);
}

export async function getAllBalances(
  uid: string,
  name: string,
  email: string,
  contact: string,
  address: string,
  coinidx: string,
  offset: string
) {
  const baseUrl = "/coin-info/balances";
  const params = { uid, name, email, contact, offset, coinidx };
  return AdminApi.get<CoinInfoBalancesResBody>(`${baseUrl}`, { params }).then(
    (resp) => resp.data
  );
}
