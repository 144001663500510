import React, { FC, useContext } from "react";
import { useLocation } from "react-router";
import { Layout, Menu } from "antd";

import SideBar from "./SideBar";
import HeaderComponent from "./Header";

import { excludeHeaderPage } from "../constants";

const BaseLayout: FC = ({ children }) => {
  const { Content } = Layout;
  const location = useLocation();
  const excludeLayoutPage = excludeHeaderPage.includes(location.pathname);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {!excludeLayoutPage ? <HeaderComponent /> : null}
      <Layout>
        {!excludeLayoutPage ? <SideBar /> : null}
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
