import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { routes } from "../constants";
import LoginPage from "../pages/Login";
import UserPage from "../pages/Users";
import UserDashboardPage from "../pages/Users/Dashboard";
import NationStatisticsPage from "../pages/Users/Dashboard/nation_statistics";
import AccountPage from "../Accounts";
import TopBannerPage from "../pages/Banner/Top";
import Home from "../pages/Home";
import News from "../pages/News";
import AdminWalletPage from "../pages/Admin/Wallet";
import TransactionPage from "../pages/Transaction";
import BalancesPage from "../pages/Users/Balances";
import Cafe24InfoPage from "../pages/Shop/Cafe24Info";
import ShopPointPage from "../pages/Shop/Point";
import jwt_decode from "jwt-decode";

let level = 0;
const access_token = JSON.parse(localStorage.getItem("access_token"));

if(access_token) {
  level = jwt_decode(access_token)['level']
}

export const Router = () => {
  const {
    LOGIN,
    USER,
    USER_DASHBOARD,
    NATION_STATISTICS,
    ACCOUNTS,
    BANNER_TOP,
    HOME,
    NEWS,
    ADMIN_WALLET,
    TRANSACTION,
    BALANCES,
    POINT,
    CAFE24_INFO,
  } = routes;
  return (
    <Switch>
      <Route exact path={LOGIN} component={LoginPage} />
      <Route exact path={USER} component={level != 2 && UserPage} />
      <Route exact path={USER_DASHBOARD} component={level != 2 && UserDashboardPage} />
      <Route exact path={NATION_STATISTICS} component={level != 2 && NationStatisticsPage} />
      <Route exact path={ACCOUNTS} component={level == 0 && AccountPage} />
      <Route exact path={BANNER_TOP} component={level != 2 && TopBannerPage} />
      <Route exact path={HOME} component={Home} />
      <Route exact path={NEWS} component={News} />
      <Route exact path={ADMIN_WALLET} component={level == 0 && AdminWalletPage} />
      <Route exact path={BALANCES} component={level != 2 && BalancesPage} />
      <Route exact path={TRANSACTION} component={level != 2 && TransactionPage} />
      <Route exact path={CAFE24_INFO} component={level != 2 && Cafe24InfoPage} />
      <Route exact path={POINT} component={level != 2 && ShopPointPage} />
    </Switch>
  );
};
