import React, { useRef, useState, VFC } from "react";
import { Button, Col, Form, Input, Modal, Row, Switch, Image } from "antd";
import { addTopBanner, uploadImage } from "../../../service/Banner";

export interface CustomModalProps {
  isVisible: boolean;
  modalHandler: () => void;
  id?: number;
}

const AddTopBannerModal: VFC<CustomModalProps> = ({
  isVisible,
  modalHandler,
}) => {
  const inputOpenImageRef = useRef<HTMLInputElement>(null);
  const [imageURL, setImageURL] = useState("");

  const [popupChecked, setPopupChecked] = useState(true);
  const [enabledChecked, setEnabledChecked] = useState(true);
  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const formData = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    formData.append("file", e.target.files[0]);

    try {
      const result = await uploadImage(formData);
      console.log(result, "result");
      const { url } = result;
      setImageURL(url);
    } catch (err) {
      alert("이미지 업로드에 실패했습니다.");
    }
  };

  const onFinish = async (value: { url: string }) => {
    const { url } = value;
    const body = {
      url,
      background: imageURL,
      popup: popupChecked,
      enabled: enabledChecked,
    };
    try {
      await addTopBanner(body);
      alert("정상적으로 등록되었습니다.");
      window.location.reload();
    } catch (err) {
      alert("에러기 발생했습니다.");
    }
  };

  return (
    <Modal
      title="상단배너 추가"
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form name="basic" wrapperCol={{ span: 16 }} onFinish={onFinish}>
        <Form.Item
          label="url"
          name="url"
          rules={[{ required: true, message: "url을 입력하세요!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="이미지" name="background">
          {imageURL ? (
            <div style={{ display: "flex" }}>
              <Image src={imageURL} />
              <Button
                onClick={() => {
                  setImageURL("");
                }}
              >
              </Button>
            </div>
          ) : (
            <Button onClick={handleImageClick}>이미지 업로드</Button>
          )}
        </Form.Item>

        <Row>
          <Col span={12}>
            <Form.Item label="팝업 여부" name="popup">
              <Switch
                checked={popupChecked}
                onClick={() => {
                  setPopupChecked((prevState) => !prevState);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="활성화 여부" name="enabled">
              <Switch
                checked={enabledChecked}
                onClick={() => {
                  setEnabledChecked((prevState) => !prevState);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" htmlType="submit">
            생성
          </Button>
        </Form.Item>
      </Form>
      <input
        type="file"
        accept="image/jpeg, image/png"
        style={{ display: "none" }}
        ref={inputOpenImageRef}
        onChange={handleImageUpload}
      />
    </Modal>
  );
};

export default AddTopBannerModal;
