import { Component, useContext, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Image, Avatar, Badge } from "antd";

import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { antdTableFilterProperty } from "../../components/antd/tableFilter";
import { numberWithCommas } from "../../utils";
import { parseDateTime, parsePhoneNumber } from "../../utils/date";
import { getUserAccounts } from "../../service/User";
import LoadingPage from "../../components/Loading";
import BaseTable from "../../components/Table/BaseTable";
import UpdateUserAccountModal from "../../components/Modal/account/UpdateUserAccount";
import useModal from "antd/es/modal/useModal";
import useModalHandler from "../../hooks/useModalHandler";
import jwt_decode from "jwt-decode";

const UserPage = () => {
  const [accounts, setAccounts] = useState<AccountResBody[] | null>(null);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState<any>("");
  const [searchedColumn, setSearchColumn] = useState<any>(null);

  const [searchEmail, setSearchEmail] = useState<any>("");
  const [searchedEmailColumn, SetSearchedEmailColumn] = useState<any>("");

  const [searchId, setSearchId] = useState<any>("");
  const [searchedIdColumn, setSerachedIdColumn] = useState<any>("");

  const [searchPhoneNumber, setSearchPhoneNumber] = useState<any>("");

  const [uid, setUid] = useState("");

  const [
    searchedPhoneNumberColumn,
    setSearchedPhoneNumberColumn,
  ] = useState<any>("");

  const [
    isVisibleUpdateModal,
    onChangeUpdateModal,
    setUpdateModal,
  ] = useModalHandler(false);

  const onClickUidHandler = (id: string) => () => {
    setUid(id);
    onChangeUpdateModal();
  };

  let level = 0;
  const access_token = JSON.parse(localStorage.getItem("access_token"));

  if(access_token) {
    level = jwt_decode(access_token)['level']
  }

  // const setLoading = useContext(SetLoadingContext)!;
  const userColumn: ColumnsType<AccountResBody> = [
    {
      key: "uid",
      dataIndex: "uid",
      title: "아이디",
      align: "center",
      width: 150,
      fixed: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        antdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "name",
          setSearchId,
          setSerachedIdColumn
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (record["uid"]) {
          const str = value.toString();
          return record["uid"].toString().includes(str);
        } else return false;
      },
      render: (_, account) => (
        level == 1 ? account.uid : <a onClick={onClickUidHandler(account.uid)}>{account.uid}</a>
      ),
    },
    {
      key: "profile_image",
      dataIndex: "profile",
      title: "프로필_사진",
      align: "center",
      width: 100,
      fixed: true,
      render: (value) =>
        value ? (
          <Image
            style={{ borderRadius: "50%", width: "40px" }}
            src={value}
            alt="profile_image"
          />
        ) : (
          <Avatar size="large" icon={<UserOutlined />} />
        ),
    },
    {
      key: "name",
      dataIndex: "name",
      title: "이름",
      fixed: true,
      align: "center",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        antdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "name",
          setSearchText,
          setSearchColumn
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (record["name"]) {
          const str = value.toString();
          return record["name"].toString().includes(str);
        } else return false;
      },
    },
    {
      key: "email",
      dataIndex: "email",
      title: "이메일",
      align: "center",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        antdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "email",
          setSearchEmail,
          SetSearchedEmailColumn
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        console.log(value);

        if (record["email"]) {
          const str = value.toString();
          return record["email"].toString().includes(str);
        } else return false;
      },
    },
    {
      key: "contact",
      title: "연락처",
      align: "center",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        antdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "contact",
          setSearchPhoneNumber,
          setSearchedPhoneNumberColumn
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (record["contact"]) {
          let modifyContact = value.toString().replace(/-/gi, ""); // "-" 제거
          if (modifyContact.length > 10){
            modifyContact = modifyContact.replace(/(^0+)/, ""); // 전화번호 10자리 초과 검색시, 맨 왼쪽 "0" 제거
          }
          return record["contact"].toString().includes(modifyContact);
        } else return false;
      },
      render: (_, data) => parsePhoneNumber(data.contact),
    },
    {
      key: "social",
      dataIndex: "social",
      title: "로그인타입",
      align: "center",
      width: 120,
    },
    {
      key: "verified",
      dataIndex: "verified",
      title: "이메일 인증",
      align: "center",
      width: 100,
      render: (value) => <span>{value ? "인증완료" : "미완료"}</span>,
    },
    {
      key: "phoneVerified",
      dataIndex: "phoneVerified",
      title: "휴대폰 인증여부",
      align: "center",
      width: 100,
      render: (value) => <span>{value ? "인증완료" : "미완료"}</span>,
    },
    {
      key: "blocked",
      dataIndex: "blocked",
      title: "활성화 여부",
      align: "center",
      width: 100,
      filters: [
        { text: "활성화", value: false },
        { text: "비활성화", value: true },
      ],
      onFilter: (value, user) => {
        return typeof value === "boolean" && user.blocked === value;
      },
      render: (value) =>
        !value ? (
          <Badge color="green" text="활성화" />
        ) : (
          <Badge color="red" text="비활성화" />
        ),
    },
    {
      key: "payable",
      title: "결제가능 여부",
      align: "center",
      width: 100,
      render: (value) =>
        !value ? (
          <Badge color="green" text="가능" />
        ) : (
          <Badge color="red" text="불가능" />
        ),
    },
    {
      key: "nationCode",
      dataIndex: "nationCode",
      title: "국가코드",
      align: "center",
      width: 100,
      render: (value) => <span>{value}</span>,
    },

    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: "가입일",
      align: "center",
      width: 150,
      sorter: (prev, next) =>
        moment(prev.timestamp).unix() - moment(next.timestamp).unix(),
      render: (value) => <span>{parseDateTime(value)}</span>,
    },
  ];

  useEffect(() => {
    (async function bootstrap() {
      setLoading(true);
      await getUserAccounts()
        .then(setAccounts)
        .finally(() => {
          setLoading(false);
        });
    })();
  }, []);
  if (loading) return <LoadingPage loading={loading} />;
  return (
    <>
      <div>
        <h2>회원 수({accounts?.length})</h2>
      </div>

      <div>
        <BaseTable
          datasource={accounts && accounts.map((a) => ({ key: a.uid, ...a }))}
          columnType={userColumn}
          scroll={true}
        />
      </div>
      <UpdateUserAccountModal
        uid={uid}
        isVisible={isVisibleUpdateModal}
        modalHandler={onChangeUpdateModal}
      />
    </>
  );
};

export default UserPage;
