import { Form, Input, Modal, Image, Button, Row, Col, Switch } from "antd";
import React, { useEffect, useRef, useState, VFC } from "react";

import useInput from "../../../hooks/useInput";
import { CustomModalProps } from "./addBanner";
import {
  getTopBanner,
  modifyTopBanner,
  uploadImage,
} from "../../../service/Banner";

const ModifyTopBannerModal: VFC<CustomModalProps> = ({
  isVisible,
  modalHandler,
  id,
}) => {
  const inputOpenImageRef = useRef<HTMLInputElement>(null);

  const [topBanner, setTopBanner] = useState<TopBannerResBody | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [url, onChangeUrl, setUrl] = useInput("");
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [popupChecked, setPopupChecked] = useState(true);
  const [enabledChecked, setEnabledChecked] = useState(true);

  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const formData = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    formData.append("file", e.target.files[0]);

    try {
      const result = await uploadImage(formData);
      const { url } = result;
      setBackgroundImageUrl(url);
    } catch (err) {
      alert("이미지 업로드에 실패했습니다.");
    }
  };

  const onSubmitHandler = async () => {
    const body = {
      url,
      background: backgroundImageUrl,
      popup: popupChecked,
      enabled: enabledChecked,
    };

    try {
      await modifyTopBanner(body, id);
      alert("정상적으로 수정 되었습니다.");
      window.location.reload();
    } catch (err) {
      alert("에러발생");
    }
  };

  useEffect(() => {
    bootstrap();
  }, [isVisible]);

  const bootstrap = async () => {
    let result: TopBannerResBody;

    if (id) {
      result = await getTopBanner(id);
    }

    if (result) {
      setTopBanner(result);
      const { url, background, popup, enabled } = result;
      setUrl(url);
      setBackgroundImageUrl(background);
      setPopupChecked(popup);
      setEnabledChecked(enabled);
    }
  };
  return (
    <Modal
      title={"상단배너 수정"}
      visible={isVisible}
      onCancel={modalHandler}
      footer={null}
    >
      <Form wrapperCol={{ span: 16 }}>
        <div style={{ marginBottom: "10px" }}>
          <span
            style={{
              width: "50px",
              display: "inline-block",
              marginBottom: "10px",
            }}
          >
            URL:
          </span>
          <Input value={url} onChange={onChangeUrl} />
        </div>

        <div style={{ marginBottom: "20px" }}>
          <span
            style={{
              width: "50px",
              display: "block",
              marginBottom: "10px",
            }}
          >
            이미지:
          </span>
          {backgroundImageUrl ? (
            <div style={{ display: "flex" }}>
              <Image src={backgroundImageUrl} style={{ display: "block" }} />
              <Button
                onClick={() => {
                  setBackgroundImageUrl("");
                }}
              >
                X
              </Button>
            </div>
          ) : (
            <Button onClick={handleImageClick}>이미지 업로드</Button>
          )}
        </div>

        <Row>
          <Col span={12}>
            <div style={{ marginBottom: "10px" }}>
              <span
                style={{
                  width: "70px",
                  display: "inline-block",
                  marginBottom: "10px",
                }}
              >
                팝업여부
              </span>
              <Switch
                checked={popupChecked}
                onClick={() => {
                  setPopupChecked((prevState) => !prevState);
                }}
              />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ marginBottom: "10px" }}>
              <span
                style={{
                  width: "70px",
                  display: "inline-block",
                  marginBottom: "10px",
                  marginRight: "20px",
                }}
              >
                활성화 여부
              </span>
              <Switch
                checked={enabledChecked}
                onClick={() => {
                  setEnabledChecked((prevState) => !prevState);
                }}
              />
            </div>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type="primary" onClick={onSubmitHandler}>
            수정
          </Button>
        </Form.Item>
      </Form>
      <input
        type="file"
        accept="image/jpeg, image/png"
        style={{ display: "none" }}
        ref={inputOpenImageRef}
        onChange={handleImageUpload}
      />
    </Modal>
  );
};

export default ModifyTopBannerModal;
