import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, Input, Typography } from "antd";
import { useRecoilState } from "recoil";
import { AccountInfoAtom } from "../../store/Atoms";
import useInput from "../../hooks/useInput";
import { getMyInfo, login } from "../../service/Account";
import { AdminApi } from "../../service";

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    display: flex;
    justify-content: center;
  }
`;

export const LoginWrapper = styled.div`
  margin-top: 10%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LoginButton = styled(Button)`
  align-self: flex-end;
`;

export const EmailInput = styled(Input)`
  margin-bottom: 30px;
`;

export const PasswordInput = styled(Input.Password)`
  margin-bottom: 30px;
`;

const LoginPage = () => {
  const [accountInfoState, setAccountInfo] = useRecoilState(AccountInfoAtom);

  const router = useHistory();
  const [email, onChangeEmail, setEmail] = useInput("");
  const [password, onChangePassword, setPassword] = useInput("");

  const onSubmit = async () => {
    try {
      const token = await login({ email, password });
      const { accessToken, refreshToken } = token;

      localStorage.setItem("access_token", JSON.stringify(accessToken));
      localStorage.setItem("refresh_token", JSON.stringify(refreshToken));

      AdminApi.defaults.headers = {
        authorization: `Bearer ${accessToken}`,
      } as any;
      const myInfo = await getMyInfo();
      console.log(myInfo, "myInfo");
      setAccountInfo({
        email: myInfo.email,
        name: myInfo.name,
        adminId: myInfo.adminId,
        level: myInfo.level,
      });

      console.log("account loing", accountInfoState);

      await router.push("/users/dashboard");
    } catch (err) {
      if (err.response.status === 404) {
        alert("이메일 / 비밀번호를 확인해주세요.");
        return;
      }
      alert(`로그인에 실패했습니다. ${err}`);
    }
  };

  useEffect(() => {
    (async function bootstrap() {
      try {
        const account = await getMyInfo();
        if (account) {
          await router.push("/");
        }
      } catch (err) {}
    })();
  }, []);

  const onCheckLogin = (e) => {
    if(e.key === 'Enter') {
      onSubmit()
    }
  }

  const inputRef = useRef(null);

  const onCheckEnter = (e) => {
    if(e.key === 'Enter') {
      inputRef.current.focus();
    }
  }

  return (
    <LoginContainer>
      <LoginWrapper>
        <Typography.Title level={4} type="secondary">
          이메일로 로그인
        </Typography.Title>
        <EmailInput
          placeholder="admin@28vc.com"
          value={email}
          onChange={onChangeEmail}
          onKeyPress={onCheckEnter}
        />
        <PasswordInput
          placeholder="비밀번호를 입력해주세요"
          value={password}
          onChange={onChangePassword}
          onKeyPress={onCheckLogin}
          ref={inputRef}
        />
        <LoginButton type="link" onClick={onSubmit}>
          로그인
        </LoginButton>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default LoginPage;
