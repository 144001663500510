import { Layout, Menu } from "antd";
import { routes } from "../../src/constants";
import {
  GoldOutlined,
  TeamOutlined,
  UserOutlined,
  AppstoreOutlined,
  HistoryOutlined,
  MonitorOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {} from "@ant-design/icons-svg";
import jwt_decode from "jwt-decode";
import { AdminApi } from "../service/index";
import { CS_API_URL } from "../constants";

export const SideBar = () => {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const history = useHistory();

  const { TRANSACTION, POINT, CAFE24_INFO } = routes;
  
  const goToPage = (url: string) => () => {
    history.push(url);
  };

  const goToUrl = (url: string) => () => {
    window.open(url, '_blank');
  }

  let level = 0;
  const access_token = JSON.parse(localStorage.getItem("access_token"));

  if(access_token) {
    level = jwt_decode(access_token)['level']
  }

  return (
    <Sider width={200} className="site-layout-background">
      <Menu
        mode="inline"
        // defaultSelectedKeys={["1"]}
        // defaultOpenKeys={["sub1"]}
        style={{ height: "100%", borderRight: 0 }}
      >
        {level != 2 && (
          <SubMenu key="1" icon={<UserOutlined />} title="유저">
            <Menu.Item key="dashboard" onClick={goToPage("/users/dashboard")}>
              유저 통계
            </Menu.Item>
            <Menu.Item key="users" onClick={goToPage("/users")}>
              유저 조회
            </Menu.Item>
            <Menu.Item key="balances" onClick={goToPage("/admin/balances")}>
              잔고 조회
            </Menu.Item>
            <Menu.Item
              key="nation_statistics"
              onClick={goToPage("/users/dashboard/nation_statistics")}>
              국가 분포
            </Menu.Item>
          </SubMenu>
        )}

        {level == 0 && (
          <SubMenu key="2" icon={<TeamOutlined />} title="어드민 계정">
            <Menu.Item key="admin_accounts" onClick={goToPage("/accounts")}>
              계정 조회
            </Menu.Item>

            <Menu.Item key="wallet_admin" onClick={goToPage("/admin/wallet")}>
              지갑 관리자
            </Menu.Item>
          </SubMenu>
        )}

        {level != 2 && (
          <SubMenu key="3" icon={<TeamOutlined />} title="배너 관리">
            <Menu.Item key="top_banner" onClick={goToPage("/banner/top")}>
              상단 배너 관리
            </Menu.Item>
          </SubMenu>
        )}

        <SubMenu key="4" icon={<TeamOutlined />} title="게시글 관리">
          <Menu.Item key="home" onClick={goToPage("/home")}>
            홈 게시글 관리
          </Menu.Item>
          <Menu.Item key="news" onClick={goToPage("/news")}>
            뉴스 게시글 관리
          </Menu.Item>
        </SubMenu>

        {level != 2 && (
          <SubMenu key="5" icon={<MonitorOutlined />} title="트렌젝션">
            <Menu.Item key="transaction" onClick={goToPage(TRANSACTION)}>
              트렌젝션 조회
            </Menu.Item>
          </SubMenu>
        )}

        {level != 2 && (
          <SubMenu key="6" icon={<ShopOutlined />} title="쇼핑관리">
            <Menu.Item key="cafe24" onClick={goToPage(CAFE24_INFO)}>
              cafe24 정보
            </Menu.Item>

            <Menu.Item key="point" onClick={goToPage(POINT)}>
              포인트 전환
            </Menu.Item>
          </SubMenu>
        )}

        {level != 2 && (
          <SubMenu key="7" icon={<ShopOutlined />} title="CS">
            <Menu.Item key="cs" onClick={goToUrl(CS_API_URL)}>
              1:1 문의
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    </Sider>
  );
};

export default SideBar;
