import { HeaderContainer } from "../../../Accounts";
import React, { useEffect, useState } from "react";
import { getAllBalances } from "../../../service/Account";
import useInput from "../../../hooks/useInput";
import { Badge, Button, Card, Col, Row, Select, Table } from "antd";
import { getCoinInfoObjType } from "../../../service/CoinInfo";
import { ColumnsType } from "antd/es/table";
import { customAntdTableFilterProperty } from "../../../components/antd/tableFilter";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { parseDateTime, parsePhoneNumber } from "../../../utils/date";
import {
  getAllTransaction,
  searchTransactions,
} from "../../../service/Transaction";
import { convertCoinInfoFunc } from "../../../utils";
import LoadingPage from "../../../components/Loading";
import useModalHandler from "../../../hooks/useModalHandler";
import UserBalanceListModal from "../../../components/Modal/balance/UserBalanceList";
import CoinWithdrawModal from "../../../components/Modal/balance/CoinWithdraw";
import jwt_decode from "jwt-decode";
import { createUserWalletLock } from "../../../service/CoinInfo";

const BalancesPage = () => {
  const [balances, setBalances] = useState<Balances[]>([]);
  const [uid, onChangeUid, setUid] = useInput("");
  const [name, onChangeName, setName] = useInput("");
  const [email, onChangeEmail, setEmail] = useInput("");
  const [contact, onChangeContact, setContact] = useInput("");
  const [address, onChangeAddress, setAddress] = useInput("");
  const [coinInfo, setCoinInfo] = useState<{ [key: string]: string }>({});
  const [coin, setCoin] = useState<string[]>([]);
  const [balancesListModal, onChangeBalancesListModal, setBalancesListModal] =
    useModalHandler(false);
  const [coinWithdrawModal, onCoinWithdrawModal, setCoinWithdrawModal] =
    useModalHandler(false);
  const [selectId, setSelectId] = useState("");

  const [convertCoinInfo, setConvertCoinInfo] = useState<{
    [key: string]: string;
  }>({});
  const [currentCoin, setCurrentCoin] = useState("");
  const [paginationOffset, setPaginationOffset] = useState("1");

  const [searchedTxidColumn, SetSearchedTxidColumn] = useState<any>("");

  const [total, setTotal] = useState("");
  const [loading, setLoading] = useState(false);
  const [coinidx, setCoinIdx] = useState(0);
  const onChangePagination = async (e: number) => {
    setPaginationOffset(String(e));
    setLoading(true);
    const result = await getAllBalances(
      uid ? uid : "",
      name ? name : "",
      email ? email : "",
      contact ? contact : "",
      address ? address : "",
      currentCoin ? convertCoinInfo[currentCoin] : "",
      String(e)
    );
    setLoading(false);

    setTotal(result.total);
    setBalances(result.data);
  };

  const onResetSearch = () => {
    window.location.reload();
  };

  let level = 0;
  let sub = 0;
  const access_token = JSON.parse(localStorage.getItem("access_token"));

  if(access_token) {
    level = jwt_decode(access_token)['level']
    sub = jwt_decode(access_token)['sub']
  }


  const columns: ColumnsType<Balances> = [
    {
      key: "uid",
      dataIndex: "uid",
      title: "uid",
      width: 130,
      align: "center",
      fixed: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "uid",
          setUid,
          SetSearchedTxidColumn,
          uid
        ),
      filterIcon: (filtered) => {
        return <SearchOutlined />;
      },
      render: (_, bal) => (
        level == 1 ? bal.uid : 
        <a
          onClick={() => {
            setSelectId(bal.uid);
            setCoinIdx(bal.coinidx);
            onChangeBalancesListModal();
          }}
        >
          {bal.uid}
        </a>
      ),
    },
    {
      key: "coinidx",
      dataIndex: "coinidx",
      title: "coin",
      align: "center",
      width: 100,
      fixed: true,
      render: (_, coin) => <>{coinInfo ? coinInfo[coin.coinidx] : ""}</>,
    },
    {
      key: "name",
      dataIndex: "name",
      title: "name",
      align: "center",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "name",
          setName,
          SetSearchedTxidColumn,
          name
        ),
      filterIcon: (filtered) => {
        return <SearchOutlined />;
      },
    },
    {
      key: "email",
      dataIndex: "email",
      title: "email",
      align: "center",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "email",
          setEmail,
          SetSearchedTxidColumn,
          email
        ),
      filterIcon: (filtered) => <SearchOutlined />,
    },
    {
      key: "contact",
      dataIndex: "contact",
      title: "휴대폰 번호",
      align: "center",
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) =>
        customAntdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          "contact",
          setContact,
          SetSearchedTxidColumn,
          contact
        ),
      filterIcon: (filtered) => <SearchOutlined />,
      render: (contact) => <>{parsePhoneNumber(contact)}</>,
    },
    {
      key: "address",
      dataIndex: "address",
      title: "코인 주소",
      align: "center",
      width: 200,
      render(address) {
        const imgUrl = "/copy.png"
        return (
          address != null && <div>
              <p style={{ display: "inline-block",
                          marginRight: 10,
                          width: "150px" }}>
                  {String(address).substr(0, 15)+'...'}
              </p>
              <img title="복사하기"
                style={{cursor: "pointer", transform: "scale(0.5,0.5)"}}
                src={imgUrl}
                onClick={() => navigator.clipboard.writeText(address)}
              />
          </div>
        )
      }
    },
    {
      key: "balance",
      dataIndex: "balance",
      title: "잔액",
      align: "center",
      width: 150,
      render: (_, bal) => (
        level != 0 ? 
            bal.coinidx == 1 ? Number(bal.balance).toFixed(6) :
                Number(bal.balance).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        :
            <a onClick={() => {
                setSelectId(bal.uid);
                setCoinIdx(bal.coinidx);
                onCoinWithdrawModal();}}>
              {bal.coinidx == 1 ? Number(bal.balance).toFixed(6) :
                  Number(bal.balance).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </a>
      ),
    },
    {
      key: "pending",
      dataIndex: "pending",
      title: "대기",
      align: "center",
      width: 150,
      render(pending) {
        return(Number(pending).toFixed(4))
      }
    },
    {
      key: "lock",
      dataIndex: "lock",
      title: "잠금처리",
      align: "center",
      width: 150,
      render(lock) {
        return(Number(lock).toFixed(4))
      }
    },
    {
      key: "withdraw",
      dataIndex: "withdraw",
      title: "출금",
      align: "center",
      width: 150,
      render: (_, bal) =>
        level == 1 ? 
          bal.withdraw ? (          
            <Badge text="출금 가능" color="green" />
          ) : (
            <Badge text="잠금" color="red" />
          )
        :
          bal.withdraw ? (          
            <a onClick={() => createUserWalletLock({uid:bal.uid, coinidx:bal.coinidx})}><Badge text="출금 가능" color="green" /></a>
          ) : (
            <a onClick={() => createUserWalletLock({uid:bal.uid, coinidx:bal.coinidx})}><Badge text="잠금" color="red" /></a>
          )
    },
  ];

  const bootstrap = async () => {
    setLoading(true);
    const result = await getAllBalances(
      uid,
      name,
      email,
      contact,
      address,
      currentCoin ? convertCoinInfo[currentCoin] : "",
      "1"
    );
    const data = await getCoinInfoObjType();
    setLoading(false);
    setConvertCoinInfo(convertCoinInfoFunc(data));
    setCoinInfo(data);
    setBalances(result.data);
    setTotal(result.total);
    const coinArr = Object.values(data).map((value) => value);
    setCoin(coinArr);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  if (loading) return <LoadingPage loading={loading} />;
  console.log(loading, balances);
  return (
    <>
      <HeaderContainer>
        <h2>잔액 조회({total})</h2>
      </HeaderContainer>

      <Card style={{ marginBottom: "20px" }}>
        <Row>
          <Col>
            <div
              style={{
                lineHeight: "1.5715",
                color: "rgba(0, 0, 0, 0.45)",
                marginBottom: "20px",
              }}
            >
              Coin
            </div>
            <Select
              defaultValue={currentCoin ? currentCoin : "전체"}
              style={{ width: "100px" }}
              onChange={async (e) => {
                setLoading(true);
                setCurrentCoin(e as string);
                const result = await getAllBalances(
                  uid ? uid : "",
                  name ? name : "",
                  email ? email : "",
                  contact ? contact : "",
                  address ? address : "",
                  convertCoinInfo[e as string] as string,
                  paginationOffset
                );
                setLoading(false);
                setTotal(result.total);
                setBalances(result.data);
              }}
            >
              {coin.map((c, i) => (
                <Select.Option value={c} key={i}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Button onClick={onResetSearch} type="primary">
              검색 기록 초기화
            </Button>
          </Col>
        </Row>
      </Card>
      <div>
        <Table
          dataSource={balances.map((b, i) => ({ key: i, ...b }))}
          columns={columns}
          bordered={true}
          loading={loading}
          scroll={{ x: 2000 }}
          pagination={{
            total: parseInt(total),
            current: parseInt(paginationOffset),
            onChange: (e) => {
              onChangePagination(e);
            },
            showSizeChanger: false,
          }}
        />
      </div>
      <UserBalanceListModal
        isVisible={balancesListModal}
        modalHandler={onChangeBalancesListModal}
        id={selectId}
        coinIndex={coinidx}
      />
      <CoinWithdrawModal
        isVisible={coinWithdrawModal}
        modalHandler={onCoinWithdrawModal}
        id={selectId}
        coinIndex={coinidx}
      />
    </>
  );
};

export default BalancesPage;
