import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RecoilRoot } from "recoil";

import "antd/dist/antd.css";

import "antd/dist/antd.css";

import { Router } from "./routes";
import { Button } from "antd";
import { excludeHeaderPage } from "./constants";
import Header from "./layouts/Header";
import BaseLayout from "./layouts/BaseLayout";
import { getMyInfo } from "./service/Account";
import LoadingPage from "./components/Loading";

function App() {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const bootstrap = async () => {
    try {
      const account = await getMyInfo();
      if (!account) {
        await history.replace("/login");
      }
    } catch (err) {
      await history.replace("/login");
    }
  };

  useEffect(() => {
    setLoading(true);
    bootstrap().finally(() => {
      setLoading(false);
    });
  }, []);
  // if (loading) return <LoadingPage loading={loading} />;

  return (
    <RecoilRoot>
      <BaseLayout>
        <Router />
      </BaseLayout>
    </RecoilRoot>
  );
}

export default App;
