import { AdminApi } from "../index";
import {
  CoinManagersReqBody,
  CoinManagersResBody,
  GetCoinManagerResBody,
  UpdateCoinManager,
} from "../../types/coin_mangers";

const baseUrl = "/coin-managers";

export async function getAllCoinManagers() {
  return AdminApi.get<CoinManagersResBody[]>(`${baseUrl}`).then(
    (resp) => resp.data
  );
}

export async function createCoinManager(body: CoinManagersReqBody) {
  return AdminApi.post(`${baseUrl}`, body);
}

export async function updateCoinManager(
  idx: number | string,
  body: UpdateCoinManager
) {
  return AdminApi.put(`${baseUrl}/${idx}`, body);
}

export async function deleteCoinManager(idx: number | string) {
  return AdminApi.delete(`${baseUrl}/${idx}`);
}

export async function getCoinManager(idx: number | string) {
  return AdminApi.get<GetCoinManagerResBody>(`${baseUrl}/${idx}`).then(
    (resp) => resp.data
  );
}
