import { VFC } from "react";
import { Layout, Spin, Typography } from "antd";

interface Props {
  loading?: boolean;
  skip?: boolean;
}

const LoadingPage: VFC<Props> = ({ loading, skip = false }) => {
  if (loading || skip) {
    return (
      <Layout
        className="loading-page"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
        <br />
        <Typography.Title level={3}>잠시 기다려주세요</Typography.Title>
      </Layout>
    );
  } else {
    return <></>;
  }
};

export default LoadingPage;
