import React, { VFC } from "react";
import BaseModal from "../BaseModal";
import { CustomModalProps } from "../banner/addBanner";
import { DeleteFontStyle } from "../../../styles/common";

interface Props extends CustomModalProps {
  onSubmitHandler: () => void;
  title: string;
}

const DeleteBaseModal: VFC<Props> = ({
  isVisible,
  modalHandler,
  id,
  onSubmitHandler,
  title,
}) => {
  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={onSubmitHandler}
      handleCancel={modalHandler}
      headerTitle={title}
    >
      <DeleteFontStyle>
        정말로 <strong>삭제</strong>하시겠습니까?{" "}
      </DeleteFontStyle>
    </BaseModal>
  );
};

export default DeleteBaseModal;
